<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <main class="page request-page">
    <div class="page-header">
      <div class="page-header__title">
        <a @click.prevent="$router.go(-1)" href="#" type="button" class="page__back">
          <ArrowLeftIcon />
          <span>Вернуться назад</span>
        </a>
        <h1 class="page__title">{{ me | getFIO }}</h1>
      </div>
      <div class="page-header__actions">
        <button
          type="button"
          class="btn-blue btn-blue--icon-left"
          :class="{
            'btn-blue--outline': !saveLoading,
            'btn-blue--loading': saveLoading,
          }"
          @click="save"
        >
          <LoaderIcon v-if="saveLoading" class="loading-rotate" />
          <SaveIcon v-else />
          <span>Сохранить</span>
        </button>
      </div>
    </div>
    <div class="page__container">
      <div class="page__content">
        <div class="request-tab__item">
          <div class="request-tab__header" @click="changeInfoShowStatus">
            <h2 class="request-tab__title">Персональная информация</h2>
            <ChevronUpIcon v-if="infoShowStatus === 'MOBILE'" />
            <ChevronDownIcon v-if="infoShowStatus === 'HIDDEN'" />
          </div>
          <div class="account-form" v-if="infoShowStatus !== 'HIDDEN'">
            <div class="account-form__image">
              <img :src="me.avatar" :alt="me | getFIOCut" v-if="me.avatar" />
              <span class="image-placeholder" v-else>{{ me | getFIOInitials }}</span>
              <div class="account-form__upload">
                <label
                  class="btn btn--small"
                  :class="{
                    'btn-blue--icon-left': imageLoading,
                    'btn--loading': imageLoading,
                  }"
                >
                  <input type="file" accept="image/*" v-show="false" @change="loadAvatar" />
                  <LoaderIcon v-if="imageLoading" class="loading-rotate" />
                  <span v-if="imageLoading">Загрузка</span>
                  <span v-else>Загрузить аватар</span>
                </label>
                <button
                  class="btn-gray btn-gray--small btn-gray--outline"
                  @click="removeAvatar"
                  type="button"
                  v-if="me.avatar"
                >
                  <LoaderIcon v-if="imageRemoveLoading" class="loading-rotate" />
                  <TrashCan2Icon v-else />
                </button>
              </div>
            </div>
            <div class="account-form__inputs">
              <InputComponent
                :error="$v.form.surname.$error"
                v-model.trim.lazy="$v.form.surname.$model"
                title="Фамилия"
              />
              <InputComponent
                :error="$v.form.company_name.$error"
                v-model.trim.lazy="$v.form.company_name.$model"
                title="Организация"
              />
              <InputComponent
                :error="$v.form.current_password.$error"
                v-model.trim.lazy="$v.form.current_password.$model"
                title="Старый пароль"
                type="password"
              >
                <template v-slot:action="">
                  <EyeOffIcon v-if="!showOldPassword" />
                  <EyeOnIcon v-else />
                </template>
              </InputComponent>
              <InputComponent
                :error="$v.form.name.$error"
                v-model.trim.lazy="$v.form.name.$model"
                title="Имя"
              />
              <InputComponent
                :error="$v.form.phone.$error"
                v-model.trim.lazy="$v.form.phone.$model"
                title="Телефон"
                mask="# (###) ###-##-##"
              />
              <InputComponent
                :error="$v.form.password.$error"
                v-model.trim.lazy="$v.form.password.$model"
                title="Новый пароль"
                type="password"
              >
                <template v-slot:action="">
                  <EyeOffIcon v-if="!showNewPassword" />
                  <EyeOnIcon v-else />
                </template>
              </InputComponent>
              <InputComponent
                :error="$v.form.patronymic.$error"
                v-model.trim.lazy="$v.form.patronymic.$model"
                title="Отчество"
              />
              <InputComponent
                :error="$v.form.email.$error"
                v-model.trim.lazy="$v.form.email.$model"
                title="Электронная почта"
                type="email"
              />
              <InputComponent
                :error="$v.form.password_confirm.$error"
                v-model.trim.lazy="$v.form.password_confirm.$model"
                title="Повторите новый пароль"
                type="password"
              >
                <template v-slot:action="">
                  <EyeOffIcon v-if="!showNewPasswordConfirmation" />
                  <EyeOnIcon v-else />
                </template>
              </InputComponent>
            </div>
          </div>
        </div>
        <div class="request-tab__item">
          <div class="request-tab__header" @click="changeHistoryShowStatus">
            <h2 class="request-tab__title">История активности</h2>
            <ChevronUpIcon v-if="historyShowStatus === 'MOBILE'" />
            <ChevronDownIcon v-if="historyShowStatus === 'HIDDEN'" />
          </div>
          <Table
            :columns="columns"
            :rows="history"
            listClass="cargo-card-list"
            v-if="historyShowStatus !== 'HIDDEN'"
          >
            <div slot="empty" class="td-container">
              <span class="table-span">
                <template>Данных нет</template>
              </span>
            </div>
            <template slot="table-row" slot-scope="{ row, column }">
              <div class="td-container" v-if="column.field === 'activity'">
                <span class="date">
                  <span class="date__main">{{ row.last_used_at | formatDate }}</span>
                  <span class="date__sub">{{ row.last_used_at | formatTime }}</span>
                </span>
              </div>
              <div class="td-container" v-else-if="column.field === 'device'">
                <span class="table-span">
                  {{ row.user_agent }}
                </span>
              </div>
            </template>
            <div slot="table-m-item" slot-scope="{ row }" class="cargo-card">
              <div class="cargo-card__body">
                <div class="cargo-card__item">
                  <span class="cargo-card__item-title">Активность</span>
                  <span class="cargo-card__item-value">
                    {{ row.last_used_at | formatDateTime }}
                  </span>
                </div>
                <div class="cargo-card__item">
                  <span class="cargo-card__item-title">Устройство</span>
                  <span class="cargo-card__item-value">{{ row.user_agent }}</span>
                </div>
              </div>
            </div>
          </Table>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ArrowLeftIcon from "@/components/svg/ArrowLeftIcon.vue";
import ChevronUpIcon from "@/components/svg/ChevronUpIcon.vue";
import ChevronDownIcon from "@/components/svg/ChevronDownIcon.vue";
import Table from "@/components/Table.vue";
import TrashCan2Icon from "@/components/svg/TrashCan2Icon.vue";
import InputComponent from "@/components/inputs/InputComponent.vue";
import EyeOnIcon from "@/components/svg/EyeOnIcon.vue";
import EyeOffIcon from "@/components/svg/EyeOffIcon.vue";
import { email, minLength, required, sameAs, requiredIf } from "vuelidate/lib/validators";
import LoaderIcon from "@/components/svg/LoaderIcon.vue";
import SaveIcon from "../../components/svg/SaveIcon.vue";

export default {
  name: "UserProfilePage",
  data() {
    return {
      saveLoading: false,
      imageLoading: false,
      imageRemoveLoading: false,
      showOldPassword: false,
      showNewPassword: false,
      showNewPasswordConfirmation: false,
      infoShowStatus: window.innerWidth > 768 ? "DESKTOP" : "HIDDEN",
      historyShowStatus: window.innerWidth > 768 ? "DESKTOP" : "HIDDEN",
      columns: [
        {
          label: "Активность",
          field: "activity",
          width: "176px",
          sortable: true,
        },
        {
          label: "Устройство",
          field: "device",
          sortable: true,
        },
      ],
      form: {
        avatar: null,
        surname: null,
        name: null,
        patronymic: null,
        company_name: null,
        phone: null,
        email: null,
        current_password: null,
        password: null,
        password_confirm: null,
      },
    };
  },
  validations: {
    form: {
      surname: {
        required,
      },
      name: {
        required,
      },
      patronymic: {
        required,
      },
      company_name: {
        required,
      },
      phone: {
        required,
        minLength: minLength(17),
      },
      email: {
        required,
        email,
        minLength: minLength(5),
      },
      current_password: {
        required: requiredIf(function () {
          return !!this.form.password;
        }),
        minLength: minLength(8),
      },
      password: {
        minLength: minLength(8),
      },
      password_confirm: {
        required: requiredIf(function () {
          return !!this.form.password;
        }),
        sameAsPassword: sameAs(function () {
          return this.form.password;
        }),
      },
    },
  },
  created() {
    Object.keys(this.form).forEach((k) => {
      const has = Object.prototype.hasOwnProperty;
      if (has.call(this.form, k)) {
        this.form[k] = this.me[k];
      }
    });
    if (this.me.company) {
      this.form.company_name = this.me.company.company_name;
    }
  },
  computed: {
    history() {
      return this.$store.state.me.history;
    },
    me() {
      return this.$store.state.me;
    },
  },
  methods: {
    removeAvatar() {
      if (!this.imageRemoveLoading && confirm("Вы уверены?")) {
        this.imageRemoveLoading = true;
        this.$store
          .dispatch("DELETE_AVATAR", {
            context: this,
          })
          .then(() => {
            this.$store.state.me.avatar = null;
            this.imageRemoveLoading = false;
            this.$notify({
              title: "Успешно",
              text: "Изображение удалено",
              type: "success",
            });
          })
          .catch(() => {
            this.imageLoading = false;
          });
      }
    },
    loadAvatar(e) {
      if (e.target.files.length) {
        this.imageLoading = true;
        this.$store
          .dispatch("UPLOAD", {
            context: this,
            variables: {
              files: e.target.files,
              folder: "avatars",
            },
          })
          .then(({ data }) => {
            if (data.UploadDocument && data.UploadDocument.length) {
              this.form.avatar = data.UploadDocument[0].url;
              this.$store
                .dispatch("EDIT_ME", {
                  context: this,
                  variables: {
                    avatar: this.form.avatar,
                  },
                })
                .then(() => {
                  this.$store.state.me.avatar = data.UploadDocument[0].url;
                  this.imageLoading = false;
                  this.$notify({
                    title: "Успешно",
                    text: "Новое изображение сохранено",
                    type: "success",
                  });
                })
                .catch(() => {
                  this.imageLoading = false;
                });
            }
          })
          .catch(() => {
            this.imageLoading = false;
          });
      }
    },
    clean(obj) {
      for (let propName in obj) {
        if (
          Object.prototype.hasOwnProperty.call(obj, propName) &&
          (obj[propName] === null || obj[propName] === undefined)
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },
    save() {
      if (!this.saveLoading) {
        this.saveLoading = true;
        this.$store
          .dispatch("EDIT_ME", {
            context: this,
            variables: this.clean(JSON.parse(JSON.stringify(this.form))),
          })
          .then(() => {
            Object.keys(this.form).forEach((k) => {
              const has = Object.prototype.hasOwnProperty;
              if (has.call(this.me, k)) {
                this.me[k] = this.form[k];
              }
            });
            if (this.me.company) {
              this.me.company.company_name = this.form.company_name;
            }
            this.saveLoading = false;
            this.$notify({
              title: "Успешно",
              text: "Личные данные сохранены",
              type: "success",
            });
          })
          .catch(({ graphQLErrors }) => {
            this.$store.dispatch("notifyGQErrors", {
              context: this,
              errors: graphQLErrors,
            });
            this.saveLoading = false;
          });
      }
    },
    changeInfoShowStatus() {
      if (window.innerWidth <= 768) {
        if (this.infoShowStatus === "MOBILE") {
          this.infoShowStatus = "HIDDEN";
        } else {
          this.infoShowStatus = "MOBILE";
        }
      } else {
        this.infoShowStatus = "DESKTOP";
      }
    },
    changeHistoryShowStatus() {
      if (window.innerWidth <= 768) {
        if (this.historyShowStatus === "MOBILE") {
          this.historyShowStatus = "HIDDEN";
        } else {
          this.historyShowStatus = "MOBILE";
        }
      } else {
        this.historyShowStatus = "DESKTOP";
      }
    },
  },
  components: {
    SaveIcon,
    LoaderIcon,
    EyeOffIcon,
    EyeOnIcon,
    InputComponent,
    TrashCan2Icon,
    Table,
    ChevronDownIcon,
    ChevronUpIcon,
    ArrowLeftIcon,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/request"
.account-form {
  @extend .request-tab__form
  grid-template-columns 3fr 9fr
  align-items center
  +below(1240px) {
    align-items start
  }
  +below(768px) {
    grid-template-columns 1fr
    grid-gap 32px
  }

  &__image {
    display grid
    grid-gap 12px
    align-items center
    justify-items center

    .image-placeholder {
      font-size 2em
    }

    .image-placeholder
    img {
      border-radius 100%
      width 140px
      height 140px
      object-fit cover
      object-position center
    }
  }

  &__upload {
    display: flex;
    align-items center
    gap 12px

    .btn {
      min-width 145px
    }

    .btn-gray {
      min-width auto
    }
  }

  &__inputs {
    display grid
    grid-template-columns 1fr 1fr 1fr
    grid-gap 12px
    +below(1240px) {
      grid-template-columns 1fr 1fr
    }
    +below(768px) {
      grid-template-columns 1fr
    }
  }
}
</style>
